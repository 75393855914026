import React, { useEffect } from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import Seo from '../components/seo';

const Wrapper = styled.section`
    display: block;
    position: relative;
    width: 100%;
    background: #0e1828;
    color: #fff;
    padding-top: 8rem;
    .outer-wrapper {
        display: block;
        width: 100%;
        max-width: 860px;
        margin-left: auto;
        margin-right: auto;
        .inner-wrapper {
            display: block;
            width: 100%;
            padding: 4rem;
            h1 {
                font-family: 'Poppins';
                font-size: 4rem;
                line-height: 1.2;
                font-weight: 600;
                text-transform: uppercase;
                margin-bottom: 6rem;
            }
            h4 {
                font-size: 1.4rem;
                line-height: 1.4;
            }
            ul,
            ol {
                li {
                    font-size: 1.4rem;
                    line-height: 1.4;
                }
            }
            p {
                font-size: 1.4rem;
                line-height: 1.4;
                margin: 2rem 0;
            }
            td {
                border-bottom: 1px solid #fff;
                font-size: 1.4rem;
                line-height: 1.4;
            }
        }
    }
    @media only screen and (min-width: 768px) {
        padding-top: 15rem;
    }
    @media only screen and (min-width: 1024px) {
        .outer-wrapper {
            .inner-wrapper {
                h1 {
                    font-size: 12rem;
                    line-height: 1;
                }
            }
        }
    }
`;

const PrivacyPolicyPage = ({ transitionStatus }) => {
    useEffect(() => {
        console.log('Privacy Policy Page', transitionStatus);
    }, [transitionStatus]);

    return (
        <Layout>
            <Seo title="Privacy Policy" />
            <Wrapper>
                <div className="outer-wrapper">
                    <div className="inner-wrapper">
                        <h1>
                            Privacy
                            <br /> Policy
                        </h1>
                        <h2>Introduction</h2>
                        <p>Welcome to Digital Velocity’s privacy notice.</p>
                        <p>
                            Digital Velocity respects your privacy and is committed to protecting
                            your personal data. This privacy notice will inform you as to how we
                            look after your personal data when you visit our website (regardless of
                            where you visit it from) and tell you about your privacy rights and how
                            the law protects you.
                        </p>
                        <p>
                            Please use the Glossary at the end to understand the meaning of some of
                            the terms used in this privacy notice.
                        </p>

                        <h2>1. Important information and who we are</h2>
                        <h3>Purpose of this privacy notice</h3>
                        <p>
                            This privacy notice aims to give you information on how Digital Velocity
                            collects and processes your personal data through your use of this
                            website and the personal data of our clients’ customers, including any
                            data you may provide through this website when you leave any contact
                            details.
                        </p>
                        <p>
                            This website is not intended for children and we do not knowingly
                            collect data relating to children.
                        </p>
                        <p>
                            It is important that you read this privacy notice together with any
                            other privacy notice or fair processing notice we may provide on
                            specific occasions when we are collecting or processing personal data
                            about you so that you are fully aware of how and why we are using your
                            data. This privacy notice supplements the other notices and is not
                            intended to override them.
                        </p>

                        <h3>Controller</h3>
                        <p>
                            Digital Velocity Limited (collectively referred to as “Digital
                            Velocity”, “we”, “us” or “our” in this privacy notice) is the controller
                            and responsible for your personal data where you are a client of Digital
                            Velocity or we collect data about you through your use of the website.
                            Please note that where we process data in connection with services we
                            perform for our clients, we act as data processor only.
                        </p>
                        <p>
                            We have appointed a data privacy manager who is responsible for
                            overseeing questions in relation to this privacy notice. If you have any
                            questions about this privacy notice, including any requests to exercise
                            your legal rights, please contact the data privacy manager using the
                            details set out below.
                        </p>

                        <h3>Contact details</h3>
                        <p>Our full details are:</p>
                        <p>
                            Full name of legal entity: Digital Velocity Limited
                            <br />
                            Name or title of data privacy manager: Thomas Kemp
                            <br />
                            Email address: info@digital-velocity.co.uk
                            <br />
                            Postal address: [8 Park Row, Leeds, LS1 5HD]
                        </p>
                        <p>
                            You have the right to make a complaint at any time to the Information
                            Commissioner’s Office (ICO), the UK supervisory authority for data
                            protection issues (www.ico.org.uk). We would, however, appreciate the
                            chance to deal with your concerns before you approach the ICO so please
                            contact us in the first instance.
                        </p>

                        <h3>Changes to the privacy notice and your duty to inform us of changes</h3>
                        <p>This version was introduced on [22.05.18].</p>
                        <p>
                            It is important that the personal data we hold about you is accurate and
                            current. Please keep us informed if your personal data changes during
                            your relationship with us.
                        </p>

                        <h3>Third-party links</h3>
                        <p>
                            If our website includes links to third-party websites, plug-ins and
                            applications, clicking on those links or enabling those connections may
                            allow third parties to collect or share data about you. We do not
                            control these third-party websites and are not responsible for their
                            privacy statements. When you leave our website, we encourage you to read
                            the privacy notice of every website you visit.
                        </p>

                        <h2>2. The data we collect about you</h2>
                        <p>
                            Personal data, or personal information, means any information about an
                            individual from which that person can be identified. It does not include
                            data where the identity has been removed (anonymous data).
                        </p>
                        <p>
                            We may collect, use, store and transfer different kinds of personal data
                            about you which we have grouped together follows:
                        </p>
                        <ul>
                            <li>
                                Identity Data includes first name, maiden name, last name, username
                                or similar identifier, marital status, title, date of birth and
                                gender.
                            </li>
                            <li>
                                Contact Data includes billing address, delivery address, email
                                address and telephone numbers.
                            </li>
                            <li>Financial Data includes bank account and payment card details.</li>
                            <li>
                                Transaction Data includes details about payments to and from you and
                                details of services you have purchased from us and, in relation to
                                our clients’ customers, details of products or services that such
                                customers may have purchased from our clients.
                            </li>
                            <li>
                                Technical Data includes internet protocol (IP) address, your login
                                data, browser type and version, time zone setting and location,
                                browser plug-in types and versions, operating system and platform
                                and other technology on the devices you use to access this website.
                            </li>
                            <li>
                                Profile Data includes your username and password, purchases or
                                orders made by you, your interests, preferences, feedback and any
                                survey responses.
                            </li>
                            <li>
                                Usage Data includes information about how you use our website and
                                services.
                            </li>
                            <li>
                                Marketing and Communications Data includes your preferences in
                                receiving marketing from us or our clients and our third parties and
                                your communication preferences.
                            </li>
                        </ul>
                        <p>
                            We also collect, use and share Aggregated Data such as statistical or
                            demographic data for any purpose. Aggregated Data may be derived from
                            your personal data but is not considered personal data in law as this
                            data does not directly or indirectly reveal your identity. For example,
                            we may aggregate your Usage Data to calculate the percentage of users
                            accessing a specific website feature. However, if we combine or connect
                            Aggregated Data with your personal data so that it can directly or
                            indirectly identify you, we treat the combined data as personal data
                            which will be used in accordance with this privacy notice.
                        </p>
                        <p>
                            We do not collect any Special Categories of Personal Data about you
                            (this includes details about your race or ethnicity, religious or
                            philosophical beliefs, sex life, sexual orientation, political opinions,
                            trade union membership, information about your health and genetic and
                            biometric data). Nor do we collect any information about criminal
                            convictions and offences.
                        </p>

                        <h3>If you fail to provide personal data</h3>
                        <p>
                            Where we need to collect personal data by law, or under the terms of a
                            contract we have with you or in connection with a contract between a
                            client of ours and its customer and you (either as our client or a
                            client’s customer) fail to provide that data when requested, we may not
                            be able to perform the contract we have or are trying to enter into with
                            you or our client may not be able to fulfil its obligation to, for
                            example, to provide you with goods or services. In this case, this may
                            delay or we or our client may have to cancel a product or service you
                            have with us or our client but we or our client will notify you if this
                            is the case at the time.
                        </p>

                        <h2>3. How is your personal data collected?</h2>
                        <p>
                            We use different methods to collect data from and about you including
                            through:
                        </p>
                        <ul>
                            <li>
                                Direct interactions. You may give us your Identity, Contact and
                                Financial Data by filling in forms or by corresponding with us by
                                post, phone, email or otherwise. This includes personal data you
                                provide when you:
                            </li>
                            <li>
                                apply for our services or the products or services of our clients,
                                should you be subscribing as a result of email marketing we have
                                sent on behalf of our clients;
                            </li>
                            <li>
                                subscribe to our service or publications or those of our clients
                                where we have sent such invitations on behalf of our clients;
                            </li>
                            <li>
                                request marketing to be sent to you either by us or one of our
                                clients;
                            </li>
                            <li>
                                enter a competition, promotion or survey in respect of Digital
                                Velocity or in respect of one of our clients, where we have sent
                                such an email on behalf of our clients; or
                            </li>
                            <li>give us some feedback.</li>
                        </ul>

                        <ul>
                            <li>
                                Automated technologies or interactions. As you interact with our
                                website or those of our clients that we may host, we may
                                automatically collect Technical Data about your equipment, browsing
                                actions and patterns. We collect this personal data by using
                                cookies, server logs and other similar technologies. Please see our
                                cookie policy [www.digital-velocity.co.uk/cookies] for further
                                details.
                            </li>
                        </ul>

                        <ul>
                            <li>
                                Third parties or publicly available sources. We may receive personal
                                data about you from various third parties and public sources as set
                                out below:
                            </li>
                            <li>
                                Technical Data from the following parties:
                                <ul>
                                    <li>
                                        analytics providers such as Google based outside the EU;
                                    </li>
                                    <li>
                                        search information providers such as Google based outside
                                        the EU].
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                Contact, Financial and Transaction Data from providers of technical,
                                payment and delivery services PayPal based outside the EU.
                            </li>
                            <li>
                                Identity and Contact Data from publicly availably sources such as
                                Companies House and the Electoral Register based inside the EU.
                            </li>
                            <li>Identity and Contact Data received from our clients.</li>
                        </ul>

                        <h2>4. How we use your personal data</h2>
                        <p>
                            We will only use your personal data when the law allows us to. Most
                            commonly, we will use your personal data in the following circumstances:
                        </p>

                        <ul>
                            <li>
                                Where we need to perform the contract we have entered into with our
                                clients in respect of our services.
                            </li>
                            <li>
                                Where it is necessary for our legitimate interests (or those of a
                                third party) and your interests and fundamental rights do not
                                override those interests.
                            </li>
                            <li>Where we need to comply with a legal or regulatory obligation.</li>
                        </ul>

                        <p>
                            Please see paragraph 10 below to find out more about the types of lawful
                            basis that we will rely on to process your personal data.
                        </p>
                        <p>
                            Generally, we do not rely on consent as a legal basis for processing
                            your personal data other than in relation to sending third party direct
                            marketing communications to you via email or text message. Where we are
                            sending out email marketing on behalf of our clients, we require our
                            clients to ensure they have the right to pass your contact details to
                            us. You have the right to withdraw consent to marketing at any time by
                            either contacting us or clicking on the unsubscribe link in any
                            marketing email we may send.
                        </p>

                        <h3>Purposes for which we will use your personal data</h3>
                        <p>
                            We have set out below, in a table format, a description of all the ways
                            we plan to use your personal data, and which of the legal bases we rely
                            on to do so. We have also identified what our legitimate interests are
                            where appropriate.
                        </p>
                        <p>
                            Note that we may process your personal data for more than one lawful
                            ground depending on the specific purpose for which we are using your
                            data. Please contact us if you need details about the specific legal
                            ground we are relying on to process your personal data where more than
                            one ground has been set out in the table below.
                        </p>

                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong>Purpose/Activity</strong>
                                    </td>
                                    <td>
                                        <strong>Type of data</strong>
                                    </td>
                                    <td>
                                        <strong>
                                            Lawful basis for processing including basis of
                                            legitimate interest
                                        </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>To register you as a new client</td>
                                    <td>
                                        (a) Identity
                                        <br />
                                        (b) Contact
                                    </td>
                                    <td>Performance of a contract with you</td>
                                </tr>
                                <tr>
                                    <td>
                                        To process and deliver our services or any orders placed on
                                        our clients by their customers including:
                                        <br />
                                        (a) Manage payments, fees and charges
                                        <br />
                                        (b) Collect and recover money owed to us
                                    </td>
                                    <td>
                                        (a) Identity
                                        <br />
                                        (b) Contact
                                        <br />
                                        (c) Financial
                                        <br />
                                        (d) Transaction
                                        <br />
                                        (e) Marketing and Communications
                                    </td>
                                    <td>
                                        (a) Performance of a contract with you
                                        <br />
                                        (b) Necessary for our legitimate interests (to recover debts
                                        due to us)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        To manage our relationship with you which will include:
                                        <br />
                                        (a) Notifying you about changes to our terms or privacy
                                        policy
                                        <br />
                                        (b) Asking you to leave a review or take a survey
                                    </td>
                                    <td>
                                        (a) Identity
                                        <br />
                                        (b) Contact
                                        <br />
                                        (c) Profile
                                        <br />
                                        (d) Marketing and Communications
                                    </td>
                                    <td>
                                        (a) Performance of a contract with you
                                        <br />
                                        (b) Necessary to comply with a legal obligation
                                        <br />
                                        (c) Necessary for our legitimate interests (to keep our
                                        records updated and to study how customers use our
                                        products/services)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        To enable you to partake in a prize draw, competition or
                                        complete a survey
                                    </td>
                                    <td>
                                        (a) Identity
                                        <br />
                                        (b) Contact
                                        <br />
                                        (c) Profile
                                        <br />
                                        (d) Usage
                                        <br />
                                        (e) Marketing and Communications
                                    </td>
                                    <td>
                                        (a) Performance of a contract with you
                                        <br />
                                        (b) Necessary for our legitimate interests (to study how
                                        clients and our clients’ customers use our services, to
                                        develop them and grow our business)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        To administer and protect our business and this website
                                        (including troubleshooting, data analysis, testing, system
                                        maintenance, support, reporting and hosting of data)
                                    </td>
                                    <td>
                                        (a) Identity
                                        <br />
                                        (b) Contact
                                        <br />
                                        (c) Technical
                                    </td>
                                    <td>
                                        (a) Necessary for our legitimate interests (for running our
                                        business, provision of administration and IT services,
                                        network security, to prevent fraud and in the context of a
                                        business reorganisation or group restructuring exercise)
                                        <br />
                                        (b) Necessary to comply with a legal obligation
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        To deliver relevant website content (both our website and
                                        those of our clients) and advertisements to you and measure
                                        or understand the effectiveness of the advertising we serve
                                        to you
                                    </td>
                                    <td>
                                        (a) Identity
                                        <br />
                                        (b) Contact
                                        <br />
                                        (c) Profile
                                        <br />
                                        (d) Usage
                                        <br />
                                        (e) Marketing and Communications
                                        <br />
                                        (f) Technical
                                    </td>
                                    <td>
                                        Necessary for our and our clients’ legitimate interests (to
                                        study how customers use our services (and those of our
                                        clients) to develop them, to grow our business and that of
                                        our clients and to inform our marketing strategies)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        To use data analytics to improve our website and the
                                        websites of our clients, our services and those of our
                                        clients, marketing, customer relationships and experiences
                                    </td>
                                    <td>
                                        (a) Technical
                                        <br />
                                        (b) Usage
                                    </td>
                                    <td width="331">
                                        Necessary for our legitimate interests (to define types of
                                        customers for our services and for the products and services
                                        of our clients, to keep our website updated and relevant, to
                                        develop our business and to inform our marketing strategy
                                        for both ourselves and that of our clients)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        To make suggestions and recommendations to you about goods
                                        or services that may be of interest to you for us and on
                                        behalf of our clients
                                    </td>
                                    <td>
                                        (a) Identity
                                        <br />
                                        (b) Contact
                                        <br />
                                        (c) Technical
                                        <br />
                                        (d) Usage
                                        <br />
                                        (e) Profile
                                    </td>
                                    <td>
                                        Necessary for our legitimate interests and those of our
                                        clients (to develop our services and their products and
                                        services and to grow our and their business)
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <h3>Marketing</h3>
                        <p>
                            We strive to provide you with choices regarding certain personal data
                            uses, particularly around marketing and advertising. We have established
                            the following personal data control mechanisms:
                        </p>

                        <h3>Promotional offers from us</h3>
                        <p>
                            For ourselves or on behalf of our clients, we may use your Identity,
                            Contact, Technical, Usage and Profile Data to form a view on what we
                            think you may want or need, or what may be of interest to you. This is
                            how we decide which products, services and offers may be relevant for
                            you (we call this marketing).
                        </p>
                        <p>
                            You will receive marketing communications from us if you have requested
                            information from us directly or purchased services from us and you have
                            not opted out of receiving that marketing.
                        </p>
                        <p>
                            As we handle marketing campaigns on behalf of our clients, customers of
                            our clients will receive marketing communications from our clients but
                            which have been sent by us and this may be sent if you have requested
                            information from our clients or purchased products or services from them
                            and you have not opted out of receiving that marketing.
                        </p>

                        <h3>Third-party marketing</h3>
                        <p>
                            We do not share your personal data with any third parties for marketing
                            purposes. To be clear, we look after the websites of our clients and
                            handle their marketing campaigns and so we are processing the personal
                            data of our clients’ customers but we do not share personal data between
                            our clients.
                        </p>

                        <h3>Opting out</h3>
                        <p>
                            You can ask us or our clients to stop sending you marketing messages at
                            any time by following the opt-out links on any marketing message sent to
                            you or by contacting us at any time.
                        </p>
                        <p>
                            Where you opt out of receiving the marketing messages, this will not
                            apply to personal data provided to us or our clients as a result of a
                            product/service purchase, warranty registration (for example),
                            product/service experience or other transactions.
                        </p>

                        <h3>Cookies</h3>
                        <p>
                            You can set your browser to refuse all or some browser cookies, or to
                            alert you when websites set or access cookies. If you disable or refuse
                            cookies, please note that some parts of this website (or those of our
                            clients that we manage) may become inaccessible or not function
                            properly. For more information about the cookies we use on our website,
                            please see our cookie policy [www.digital-velocity.co.uk/cookies] (but
                            please note our clients’ websites may use differing cookies and so our
                            cookie policy will not apply to our clients’ websites).
                        </p>

                        <h3>Change of purpose</h3>
                        <p>
                            We will only use your personal data for the purposes for which we or our
                            clients collected it and in line with our instructions from our clients,
                            unless we reasonably consider that we need to use it for another reason
                            and that reason is compatible with the original purpose. If you wish to
                            get an explanation as to how the processing for the new purpose is
                            compatible with the original purpose, please contact us.
                        </p>
                        <p>
                            If we need to use your personal data for an unrelated purpose, we will
                            notify you and we will explain the legal basis which allows us to do so.
                        </p>
                        <p>
                            Please note that we may process your personal data without your
                            knowledge or consent, in compliance with the above rules, where this is
                            required or permitted by law.
                        </p>

                        <h2>5. Disclosures of your personal data</h2>
                        <p>
                            We may have to share your personal data with the parties set out below
                            for the purposes set out in the table in paragraph 4 above:
                        </p>

                        <ul>
                            <li>
                                External Third Parties as set out in the Glossary in paragraph 10
                                below.
                            </li>
                            <li>
                                In respect of our clients’ customers, we may share customer
                                information with the particular client for whom we are performing a
                                service in respect of communications with their customers (but note,
                                not between clients).
                            </li>
                            <li>
                                Third parties to whom we may choose to sell, transfer, or merge
                                parts of our business or our assets. Alternatively, we may seek to
                                acquire other businesses or merge with them. If a change happens to
                                our business, then the new owners may use your personal data in the
                                same way as set out in this privacy notice.
                            </li>
                        </ul>

                        <p>
                            We require all third parties to respect the security of your personal
                            data and to treat it in accordance with the law. We do not allow our
                            third-party service providers to use your personal data for their own
                            purposes and only permit them to process your personal data for
                            specified purposes and in accordance with our instructions.
                        </p>

                        <h2>6. International transfers</h2>
                        <p>
                            We do not transfer your personal data outside the European Economic Area
                            (EEA).
                        </p>

                        <h2>7. Data security</h2>
                        <p>
                            We have put in place appropriate security measures to prevent your
                            personal data from being accidentally lost, used or accessed in an
                            unauthorised way, altered or disclosed. In addition, we limit access to
                            your personal data to those employees, agents, contractors and other
                            third parties who have a business need to know. They will only process
                            your personal data on our instructions (or on the instructions of a
                            client where such instructions relate to their customers) and they are
                            subject to a duty of confidentiality.
                        </p>
                        <p>
                            We have put in place procedures to deal with any suspected personal data
                            breach and will notify you and any applicable regulator of a breach
                            where we are legally required to do so.
                        </p>

                        <h2>8. Data retention</h2>
                        <h3>How long will you use my personal data for?</h3>
                        <p>
                            We will only retain your personal data for as long as necessary to
                            fulfil the purposes it was collected for, including for the purposes of
                            satisfying any legal, accounting, or reporting requirements.
                        </p>
                        <p>
                            To determine the appropriate retention period for personal data, we
                            consider the amount, nature, and sensitivity of the personal data, the
                            potential risk of harm from unauthorised use or disclosure of your
                            personal data, the purposes for which we process your personal data and
                            whether we can achieve those purposes through other means, and the
                            applicable legal requirements.
                        </p>
                        <p>
                            By law we have to keep basic information about our customers (including
                            Contact, Identity, Financial and Transaction Data) for six years after
                            they cease being customers for tax purposes. We will keep information
                            about our clients’ customers in accordance with our instructions from
                            our clients.
                        </p>
                        <p>
                            In some circumstances you can ask us to delete your data: see “Request
                            erasure” below for further information.
                        </p>
                        <p>
                            In some circumstances we may anonymise your personal data (so that it
                            can no longer be associated with you) for research or statistical
                            purposes in which case we may use this information indefinitely without
                            further notice to you.
                        </p>

                        <h2>9. Your legal rights</h2>
                        <p>
                            Under certain circumstances, you have rights under data protection laws
                            in relation to your personal data. Please see below to find out more
                            about these rights:
                        </p>
                        <p>
                            If you wish to exercise any of the below rights, please contact us using
                            the contact information set out above.
                        </p>

                        <h3>No fee usually required</h3>
                        <p>
                            You will not have to pay a fee to access your personal data (or to
                            exercise any of the other rights). However, we may charge a reasonable
                            fee if your request is clearly unfounded, repetitive or excessive.
                            Alternatively, we may refuse to comply with your request in these
                            circumstances.
                        </p>

                        <h3>What we may need from you</h3>
                        <p>
                            We may need to request specific information from you to help us confirm
                            your identity and ensure your right to access your personal data (or to
                            exercise any of your other rights). This is a security measure to ensure
                            that personal data is not disclosed to any person who has no right to
                            receive it. We may also contact you to ask you for further information
                            in relation to your request to speed up our response.
                        </p>

                        <h3>Time limit to respond</h3>
                        <p>
                            We try to respond to all legitimate requests within one month.
                            Occasionally it may take us longer than a month if your request is
                            particularly complex or you have made a number of requests. In this
                            case, we will notify you and keep you updated.
                        </p>

                        <h2>10. Glossary</h2>
                        <h3>LAWFUL BASIS</h3>
                        <p>
                            Legitimate Interest means the interest of our business in conducting and
                            managing our business to enable us to give you the best service/product
                            and the best and most secure experience. We make sure we consider and
                            balance any potential impact on you (both positive and negative) and
                            your rights before we process your personal data for our legitimate
                            interests. We do not use your personal data for activities where our
                            interests are overridden by the impact on you (unless we have your
                            consent or are otherwise required or permitted to by law). You can
                            obtain further information about how we assess our legitimate interests
                            against any potential impact on you in respect of specific activities by
                            contacting us.
                        </p>
                        <p>
                            Performance of Contract means processing your data where it is necessary
                            for the performance of a contract to which you are a party or to take
                            steps at your request before entering into such a contract.
                        </p>
                        <p>
                            Comply with a legal or regulatory obligation means processing your
                            personal data where it is necessary for compliance with a legal or
                            regulatory obligation that we are subject to.
                        </p>

                        <h3>THIRD PARTIES</h3>
                        <h4>External Third Parties</h4>
                        <p>
                            Service providers acting as processors based in the United Kingdom who
                            provide IT and system administration services.
                        </p>
                        <p>
                            Professional advisers acting as processors or joint controllers
                            including lawyers, bankers, auditors and insurers based in the United
                            Kingdom who provide consultancy, banking, legal, insurance and
                            accounting services.
                        </p>
                        <p>
                            HM Revenue & Customs, regulators and other authorities acting as
                            processors or joint controllers based in the United Kingdom who require
                            reporting of processing activities in certain circumstances.
                        </p>
                        <p>
                            PayPal which has its European establishment in Switzerland (Switzerland
                            affording an adequate level of protection to personal data, under a
                            European Commission decision).
                        </p>

                        <h3>YOUR LEGAL RIGHTS</h3>
                        <p>You have the right to:</p>
                        <p>
                            Request access to your personal data (commonly known as a “data subject
                            access request”). This enables you to receive a copy of the personal
                            data we hold about you and to check that we are lawfully processing it.
                        </p>
                        <p>
                            Request correction of the personal data that we hold about you. This
                            enables you to have any incomplete or inaccurate data we hold about you
                            corrected, though we may need to verify the accuracy of the new data you
                            provide to us.
                        </p>
                        <p>
                            Request erasure of your personal data. This enables you to ask us to
                            delete or remove personal data where there is no good reason for us
                            continuing to process it. You also have the right to ask us to delete or
                            remove your personal data where you have successfully exercised your
                            right to object to processing (see below), where we may have processed
                            your information unlawfully or where we are required to erase your
                            personal data to comply with local law. Note, however, that we may not
                            always be able to comply with your request of erasure for specific legal
                            reasons which will be notified to you, if applicable, at the time of
                            your request.
                        </p>
                        <p>
                            Object to processing of your personal data where we are relying on a
                            legitimate interest (or those of a third party, for example, of our
                            clients) and there is something about your particular situation which
                            makes you want to object to processing on this ground as you feel it
                            impacts on your fundamental rights and freedoms. You also have the right
                            to object where we are processing your personal data for direct
                            marketing purposes. In some cases, we may demonstrate that we have
                            compelling legitimate grounds to process your information which override
                            your rights and freedoms.
                        </p>
                        <p>
                            Request restriction of processing of your personal data. This enables
                            you to ask us to suspend the processing of your personal data in the
                            following scenarios: (a) if you want us to establish the data’s
                            accuracy; (b) where our use of the data is unlawful but you do not want
                            us to erase it; (c) where you need us to hold the data even if we no
                            longer require it as you need it to establish, exercise or defend legal
                            claims; or (d) you have objected to our use of your data but we need to
                            verify whether we have overriding legitimate grounds to use it.
                        </p>
                        <p>
                            Request the transfer of your personal data to you or to a third party.
                            We will provide to you, or a third party you have chosen, your personal
                            data in a structured, commonly used, machine-readable format. Note that
                            this right only applies to automated information which you initially
                            provided consent for us (or our clients) to use or where we used the
                            information to perform a contract with you (or between you and our
                            client if you are a customer of client).
                        </p>
                        <p>
                            Withdraw consent at any time where we are relying on consent to process
                            your personal data. However, this will not affect the lawfulness of any
                            processing carried out before you withdraw your consent. If you withdraw
                            your consent, we may not be able to provide certain services to you or
                            provide information about our clients’ products and services to you.
                        </p>
                    </div>
                </div>
            </Wrapper>
        </Layout>
    );
};

export default PrivacyPolicyPage;
